// script.js

function initCarousels() {
  var carousels = document.querySelectorAll('.carousel');
  for (var i = 0; i < carousels.length; i++) {
    tns({
      container: carousels[i],
      mouseDrag: true,
      nav: false,
    });
  }
}

function main(event) {
  var isInitialLoad = !event; // fires first time on document.ready, not pjax event

  if (isInitialLoad) {
  }

  initCarousels();

  if (window.initMap) {
    initMap();
  }
}

$(function () {
  main(false);
});

// Init Pjax
(function (window) {
  var pjax = new Pjax({
    elements: 'a[href]:not(.no-pjax)',
    selectors: ['[data-pjax-track]', 'title', '#pjax-page', '#pjax-eval'],
    switches: {
      '[data-pjax-track]': function (oldEl, newEl, options) {
        if (oldEl.outerHTML == newEl.outerHTML) {
          // nothing has changed, continue as usual
          this.onSwitch();
        } else {
          // something has changed. Abort.
          window.location.href = options.request.responseURL;
        }
      },
    },
    cacheBust: false,
  });
  document.addEventListener('pjax:success', main);

  // Loading progress indicator
  topbar.config({
    barThickness: 1,
    barColors: {
      0: '#2379B7',
      1: '#0E7DFE',
    },
    shadowBlur: 3,
  });
  document.addEventListener('pjax:send', topbar.show);
  document.addEventListener('pjax:complete', topbar.hide);
})(this);
